
import React from 'react';
import Image from 'next/image'
// import './vidioModel.css'
interface VidioModelProps {
  videoSrc: string;
  isModalOpen: boolean;
  closeModal: () => void;
}
const VidioModel: React.FC<VidioModelProps> = ({ videoSrc, isModalOpen, closeModal }) => {
  return (
    <div>
      {isModalOpen && (
        <div className="modal fade show" id="myModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  <Image src="/images/close-icon.svg" width={16} height={16} alt='close popup' loading="lazy"/>
                </button>
                <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  src={`${videoSrc}?autoplay=1&modestbranding=1&showinfo=0&rel=0`}
                  frameBorder="0"
                  allowFullScreen
                  allow='autoplay'
                  title="YouTube Video"
                ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VidioModel;






